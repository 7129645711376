import React, { useEffect, useState, useCallback, useRef } from "react";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { Button, Icon } from "../../../components";
import { Field, getIn, FormikErrors, FormikTouched } from "formik";
import AddImageAnswer from "./AddImageAnswer";
import { addStyles, EditableMathField } from "react-mathquill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import MathOperations from "../../../icons/MathOperations.svg";
import GreekLetters from "../../../icons/GreekLetters.svg";
import MiscOperations from "../../../icons/MiscOperations.svg";
import {
  mathOperations,
  greekLetters,
  miscOperations,
} from "../utilities/math/constants";
import numberToAlphabet from "utilities/convertIntegerToChar";

addStyles();

interface Option {
  option: string;
  optionImageFile?: string | null;
  optionImageUrl?: string;
  asset_id?: string | null;
  is_answer?: boolean;
  isFormulaPreview?: boolean;
}

interface Question {
  description: string | null;
  is_essay: number;
  asset: {
    id: string;
    name: string;
    url: string;
  };
  question: string;
  options: Option[];
  answer: string;
  explanation: string;
  explanation_asset_id: string;
  explanationAssetFile: string;
  explanationAssetUrl: string;
  true_score: string;
  false_score: string;
  questionAssetFile: string;
  questionAssetUrl: string;
  asset_id: string | null;
}

interface ExplanationAsset {
  name: string;
  full_asset: string;
  id: string;
}

interface Values {
  name: string;
  description: string;
  subject: string;
  difficulty: number;
  grade: string;
  true_score: string;
  false_score: string;
  questions: Question[];
  explanation_asset: ExplanationAsset;
}

type Props = {
  optionIndex: number;
  index: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<Values>>;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  question: Question;
  option: Option;
  values: Values;
};

const MathToolButton = ({
  src,
  tooltip,
  onClick,
}: {
  src: string;
  tooltip: string | { iconPath: string; label: string }[]; // Tooltip is a string or array of objects with label
  onClick: (label: string) => void;
}) => (
  <div
    className="group flex items-end py-px px-1 hover:bg-gray-100 cursor-pointer rounded-md relative"
    onClick={(e) => {
      e.preventDefault();
      if (typeof tooltip === "string") {
        onClick(tooltip); // If tooltip is a string, pass it directly
      } else {
        console.warn(
          "Tooltip is not a string. Ensure correct label is passed."
        );
      }
    }}
  >
    <img src={src} className="h-6 w-6 text-primary" alt={tooltip.toString()} />
    <FontAwesomeIcon
      icon={icon({ name: "caret-down" })}
      className="h-3 w-3 mb-1 ml-px text-black"
    />
    {Array.isArray(tooltip) && (
      <MathOperationsTool data={tooltip} onClick={onClick} />
    )}
  </div>
);

const MathOperationsTool = ({
  data = [],
  onClick,
}: {
  data: { iconPath: string; label: string }[];
  onClick: (label: string) => void;
}) => {
  return (
    <div className="z-20 hidden min-w-[150px] p-2 bg-gray-100 rounded-md absolute top-[26px] left-0 right-0 group-hover:grid grid-cols-6">
      {data.map((item) => (
        <div
          key={item.label.toString()}
          onClick={(e) => {
            e.preventDefault();
            console.log("Clicked on label: ", item.label); // Debug to confirm label
            onClick(item.label); // Pass the label directly
          }}
        >
          <img
            src={item.iconPath}
            className="p-1 w-6 h-6 text-primary"
            alt={item.label}
          />
        </div>
      ))}
    </div>
  );
};

const generateOptionPrefix = (index: number): string => {
  return `${numberToAlphabet(index + 1)}.`;
};

const ensureOptionPrefix = (optionText: string, index: number): string => {
  const prefix = generateOptionPrefix(index);
  return optionText.startsWith(prefix) ? optionText : `${prefix} ${optionText}`;
};

const MultipleChoicesItem = ({
  optionIndex,
  index,
  values,
  setFieldValue,
  errors,
  touched,
  question,
  option,
}: Props) => {
  const [isFormulaPreview, setIsFormulaPreview] = useState<boolean>(
    option.isFormulaPreview ?? false
  );
  const [key, setKey] = useState<number>(0);

  // Ref to store the previous value to prevent infinite loop
  const previousIsFormulaPreview = useRef(isFormulaPreview);

  useEffect(() => {
    const currentFieldValue = getIn(
      values,
      `questions.${index}.options.${optionIndex}.isFormulaPreview`
    );
    if (
      previousIsFormulaPreview.current !== isFormulaPreview &&
      currentFieldValue !== isFormulaPreview
    ) {
      setFieldValue(
        `questions.${index}.options.${optionIndex}.isFormulaPreview`,
        isFormulaPreview
      );
    }
    previousIsFormulaPreview.current = isFormulaPreview;
  }, [isFormulaPreview, index, optionIndex, values, setFieldValue]);

  const previousOption = useRef(option.option);
  useEffect(() => {
    const optionValue = option.option;
    const updatedValue = ensureOptionPrefix(optionValue, optionIndex);
    if (previousOption.current !== updatedValue) {
      setFieldValue(
        `questions.${index}.options.${optionIndex}.option`,
        updatedValue
      );
    }
    previousOption.current = updatedValue;
  }, [optionIndex, option.option, index, setFieldValue]);

  const updateKey = useCallback(() => setKey((prevKey) => prevKey + 1), []);

  const setValue = (val: string) => {
    const updatedVal = ensureOptionPrefix(val, optionIndex);
    setFieldValue(
      `questions.${index}.options.${optionIndex}.option`,
      updatedVal
    );
  };

  const handleRemoveOption = () => {
    const updatedQuestions = [...values.questions];
    updatedQuestions[index].options.splice(optionIndex, 1);
    setFieldValue(
      `questions[${index}].options`,
      updatedQuestions[index].options
    );
  };

  const handleFormulaPreviewToggle = (val: boolean) => {
    setIsFormulaPreview(val);
    setFieldValue(
      `questions.${index}.options.${optionIndex}.isFormulaPreview`,
      val
    );
  };

  const handleMathToolClick = (label: string) => {
    // Ensure label is a string
    const symbol = typeof label === "string" ? label : JSON.stringify(label);
    setValue(option.option + symbol);
    updateKey();
  };

  return (
    <div className="p-4 border rounded-xl">
      <div className="flex flex-col md:flex-row md:items-center">
        <label className="font-medium text-sm">
          Jawaban {generateOptionPrefix(optionIndex)}
        </label>
        <div className="flex mt-2 md:mt-0 md:ml-auto">
          <ToggleSwitch
            label="Aktifkan preview rumus"
            isChecked={isFormulaPreview}
            onChange={handleFormulaPreviewToggle}
          />
          <button
            type="button"
            title="Delete"
            disabled={optionIndex <= 1}
            onClick={handleRemoveOption}
            className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
          >
            <Button.Icon action="delete" />
          </button>
        </div>
      </div>

      {isFormulaPreview ? (
        <div className="py-2 border-b border-gray-400">
          <div>
            <span className="flex" onMouseDown={(e) => e.preventDefault()}>
              <MathToolButton
                src={GreekLetters}
                tooltip={greekLetters}
                onClick={handleMathToolClick}
              />
              <MathToolButton
                src={MiscOperations}
                tooltip={miscOperations}
                onClick={handleMathToolClick}
              />
              <MathToolButton
                src={MathOperations}
                tooltip={mathOperations}
                onClick={handleMathToolClick}
              />
            </span>
          </div>
          <EditableMathField
            key={`${optionIndex}-${key}`}
            latex={option.option}
            onChange={(val) => setValue(val.latex())}
          />
        </div>
      ) : (
        <Field
          name={`questions.${index}.options.${optionIndex}.option`}
          className={
            getIn(errors, `questions.${index}.options.${optionIndex}.option`) &&
            getIn(touched, `questions.${index}.options.${optionIndex}.option`)
              ? "outline-none border-b border-rose-400 placeholder:text-rose-400 mt-3 pb-2 w-full"
              : "outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full mb-4"
          }
          placeholder={`Masukan Jawaban ${generateOptionPrefix(optionIndex)}`}
        />
      )}
      {getIn(errors, `questions.${index}.options.${optionIndex}.option`) && (
        <p className="text-[#F04438] mt-0.5">
          {getIn(errors, `questions.${index}.options.${optionIndex}.option`)}
        </p>
      )}
      <AddImageAnswer
        onChange={(file, url, asset_id) => {
          setFieldValue(
            `questions.${index}.options.${optionIndex}.optionImageUrl`,
            url
          );
          setFieldValue(
            `questions.${index}.options.${optionIndex}.optionImageFile`,
            file
          );
          setFieldValue(
            `questions.${index}.options.${optionIndex}.asset_id`,
            asset_id
          );
        }}
        subject_id={values.subject}
        grade_id={values.grade}
        id={`questions.${index}.options.${optionIndex}.asset_id`}
      />
      {question.options[optionIndex].optionImageUrl &&
        question.options[optionIndex].optionImageUrl!.length > 0 && (
          <div className="relative h-[160px] w-full mt-5">
            <button
              type="button"
              title="close"
              onClick={() => {
                setFieldValue(
                  `questions.${index}.options.${optionIndex}.optionImageFile`,
                  null
                );
                setFieldValue(
                  `questions.${index}.options.${optionIndex}.optionImageUrl`,
                  ""
                );
                setFieldValue(
                  `questions.${index}.options.${optionIndex}.asset_id`,
                  ""
                );
              }}
              className="right-0 translate-x-1/2 -translate-y-1/2 w-6 aspect-square absolute bg-[#C7C8CA] rounded-full flex peer z-10"
            >
              <Icon.Close className="h-3 aspect-square m-auto" />
            </button>
            <div className="bg-gray-50 border border-gray-100 flex justify-start h-full relative peer-hover:opacity-70 transition-all">
              <img src={question.options[optionIndex]?.optionImageUrl} alt="" />
            </div>
          </div>
        )}
    </div>
  );
};

export default MultipleChoicesItem;

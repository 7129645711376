import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { currencyConverter, getFormatDate } from "utilities";
import Chart from "react-apexcharts";

const DUMMY_OMZET = [
  {
    name: "E-learning",
    value: 20000000,
  },
  {
    name: "Live class",
    value: 20000000,
  },
  {
    name: "Tryout",
    value: 20000000,
  },
  {
    name: "Tanya PR",
    value: 20000000,
  },
];

type Props = {
  graphData: GraphTurnover[];
  turnover: RecapTurnover | null;
};

const Turnover: FC<Props> = ({ turnover, graphData }) => {
  const [categories, setCategories] = useState([""]);
  const [data, setData] = useState([0]);

  const programTurnover = useMemo(() => {
    let listItem = [
      {
        id: "learn",
        name: "E-learning",
        value: 0,
      },
      {
        id: "class",
        name: "Live class",
        value: 0,
      },
      {
        id: "tryout",
        name: "Tryout",
        value: 0,
      },
      {
        id: "ask_homework",
        name: "Tanya PR",
        value: 0,
      },
    ];
    let total = 0;

    if (turnover) {
      listItem = listItem.map((val) => ({
        ...val,
        value: turnover[val.id as keyof RecapTurnover]
      }));
      total =
        turnover.ask_homework +
        turnover.class +
        turnover.learn +
        turnover.tryout;
    }

    return {
      listItem,
      total,
    };
  }, [turnover]);

  useEffect(() => {
    const listDate = graphData.map((val) => val.date);
    const listData = graphData.map((val) => val.summary);
    setCategories(listDate);
    setData(listData);
  }, [graphData]);

  return (
    <div className="bg-white rounded-xl px-5 py-4 space-y-5">
      <div className="space-y-5">
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={icon({ name: "wallet" })}
            className="bg-primary rounded-full p-1.5 w-3.5 h-3.5"
          />
          <span className="text-sm font-medium">Omzet</span>
        </div>
      </div>
      <p className="font-bold text-2xl">
        {currencyConverter(programTurnover.total)}
      </p>
      {/* chart */}
      <div className="w-full h-64">
        <Chart
          height={"100%"}
          width={"100%"}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              categories: categories,
              labels: {
                formatter: function (val) {
                  return `${getFormatDate(val, "DD/MM")}`;
                },
              },
            },
          }}
          series={[
            {
              name: "Omzet",
              data: data,
              color: "#2E93FA",
            },
          ]}
          type="area"
        />
      </div>
      <div className="bg-gray-50 rounded grid grid-cols-4">
        {programTurnover.listItem.map((val) => (
          <div key={val.id} className="px-4 py-2 text-center">
            <p className="font-medium text-sm">{val.name}</p>
            <span className="font-bold">{currencyConverter(val.value)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Turnover);

import { API_URL } from "constant";
import { services } from "./services";
import { AxiosError } from "axios";

type Params = {
  per_page: number;
  search: string;
  sort_by: "name" | "phone" | "email" | "grade";
  order: "asc" | "desc";
  page: number;
};

type List = PaginationResponse & {
  data: StudentData[];
};

type ListBalance = PaginationResponse & {
  data: BalanceHomework[];
};

type ListPurchase = PaginationResponse & {
  data: ProgramItem[];
};

type Errors = {
  errors: {
    [key: string]: [string];
  };
  message: string;
};

const useStudent = () => {
  const getList = async (params?: Params): Promise<List> => {
    try {
      const {
        per_page = 10,
        search = "",
        sort_by = "name",
        order = "asc",
        page = 1,
      } = params ?? {};
      const resp = await services.get(API_URL.student, {
        params: {
          per_page,
          sort_by,
          search,
          order,
          page,
        },
      });

      return resp.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: params?.per_page ?? 10,
        to: 1,
        total: 0,
      };
    }
  };

  const add = async (params: StudentPayload) => {
    try {
      const { parents, schools, grade, ...restParams } = params;
      let payload: StudentPayload = {
        ...restParams,
      };

      if (parents?.email) {
        payload.parents = parents;
      }

      if (schools?.isOther) {
        payload.schools = {
          nis: schools.nis,
          name: schools.name,
          school_class_name: schools.school_class_name,
        };
      } else {
        payload.schools = {
          id: schools?.id,
          school_class_id: schools?.school_class_id,
        };
      }

      await services.post(API_URL.student, payload);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const edit = async (id: string, params: StudentPayload) => {
    try {
      const { password, parents, ...restParams } = params;
      let payload: Partial<StudentPayload> = restParams;

      if (parents?.email) {
        payload.parents = {
          email: parents.email,
          name: parents.name,
          phone_number: parents.phone_number,
          role: parents.role,
        };
        if(parents.password) {
          payload.parents.password = parents.password;
        }
      }

      if (password) {
        payload.password = password;
      }

      await services.put(`${API_URL.student}/${id}`, payload);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const getDetail = async (id: string): Promise<StudentData> => {
    try {
      const response = await services.get(`${API_URL.student}/${id}`);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteStudent = async (id: string) => {
    try {
      await services.delete(`${API_URL.student}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  const getBalance = async (id: string): Promise<ListBalance> => {
    try {
      const response = await services.get(
        `${API_URL.student}/${id}/saldo_tanya_pr`
      );

      return response.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      };
    }
  };

  const getProgram = async (id: string): Promise<ProgramItem[]> => {
    try {
      const response = await services.get(
        `${API_URL.student}/${id}/program_subscribe`,
        {
          params: {
            per_page: 50,
            page: 1,
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      return [];
    }
  };

  const getPerformance = async (id: string): Promise<StudentPerformance> => {
    try {
      const response = await services.get(
        `${API_URL.student}/${id}/performance_graph`
      );

      return response.data?.data;
    } catch (error) {
      return {
        last_do_question: "",
        last_seen_materi: "",
        total_ask_homework_orders: 0,
        total_program_ownerships: 0,
        graph: {},
      };
    }
  };

  const getHistory = async (
    id: string,
    params?: Partial<Params>
  ): Promise<ListPurchase> => {
    try {
      const response = await services.get(
        `${API_URL.student}/${id}/history_purchase`,
        {
          params: {
            per_page: params?.per_page ?? 10,
            page: params?.page ?? 1,
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: params?.per_page ?? 10,
        to: 1,
        total: 0,
      };
    }
  };

  return {
    getList,
    add,
    deleteStudent,
    getDetail,
    edit,
    getBalance,
    getProgram,
    getPerformance,
    getHistory,
  };
};

export default useStudent;

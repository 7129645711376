export const API_URL = {
  adminBrowse: "/admin/browse",
  askHomework: "/admin/ask-homework",
  auth: "/auth",
  browse: "/browse",
  bundle: "/admin/bundle",
  class: "/admin/program/class",
  dashboard: "/admin/dashboard",
  directory: "/directory",
  grades: "/grades",
  massUpload: "/admin/mass-upload",
  module: "/module",
  program: "/admin/program",
  promo: "/admin/promotion",
  school: "/admin/school",
  student: "/admin/student",
  syllabus: "/admin/syllabus",
  teacher: "/admin/teacher",
  tryout: "/admin/tryout",
  upload: "/asset/upload",
  withdraw: "/admin/withdraw",
  liveclass: "/admin/liveclass",
  avatar: "/admin/avatar",
  leave: "/admin/teacher/leaves",
  soal: "/question-package",
  moveItem: "/admin/move-item",
  staff: "/admin/staff",
  schoolStaff: "/admin/school_staff",
  point: "/admin/user-points",
  transactions: "/admin/transactions",
  authLogout: "/auth/user/logout",
};
